<template>
  <div>
    <el-card>
      <common-user-center-title :title="'提现记录'"></common-user-center-title>
      <router-link to="/distribution/applyProfit">
        <el-button type="primary">申请提现</el-button>
      </router-link>
      <el-table :data="withdrawalList" border class="mt-2" stripe>
        <el-table-column type="index" />
        <el-table-column label="提现状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 2" type="success">提现成功</el-tag>
            <el-tag v-else-if="scope.row.status == 1" type="danger">提现失败</el-tag>
            <el-tag v-else>提现审核中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="提现方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.acc_type == 1">微信</div>
            <div v-if="scope.row.acc_type == 2">支付宝</div>
            <div v-if="scope.row.acc_type == 3">银行卡</div>
          </template>
        </el-table-column>
        <el-table-column label="提现状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_pay == 1" type="success">已支付</el-tag>
            <el-tag v-else>待支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="申请时间" prop="created_at" />
      </el-table>
      <!-- 分页区域 -->
      <el-pagination class="mt-2"
                     :current-page="queryInfo.page"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { getDistributionWithdrawalListUrl } from '@/api/index'
export default {
  name: 'WithdrawalList',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      queryInfo: {
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      withdrawalList: [],
      total: 0
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getDistributionWithdrawalList()
    },
    // 获取我的提现申请列表
    async getDistributionWithdrawalList(){
      console.log(getDistributionWithdrawalListUrl)
      const {data:res}= await this.$http.get(getDistributionWithdrawalListUrl,{params:this.queryInfo})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.withdrawalList = res.data.list
      this.total = res.data.total
    },
    // 分页页码显示条数
    handleSizeChange(newSzie) {
      // 把每页显示多少数据重新赋值
      this.queryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getDistributionWithdrawalList()
    },
    // 页面切换事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      // 重新获取数据并显示
      this.getDistributionWithdrawalList()
    },
  }
}
</script>

<style scoped>

</style>
